// HELLO WORLD
const burgerTrigger = document.querySelector(".burger");
const burgerMenu = document.querySelector(".main-nav");

const initBurgerNav = () => {
  if (!burgerTrigger || !burgerMenu) {
    return;
  }

  burgerTrigger.addEventListener("click", () => {
    burgerMenu.classList.toggle("visible");
  });
};

initBurgerNav();
